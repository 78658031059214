
import styled from 'styled-components'

import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'


import { Props } from './types'

const SectionRule = styled.hr<Props>`
  border: 0;
  height: 4px;
  background: #dcddde;
  margin: ${(props: Props): string =>
    props.fullWidth
      ? `${LAYOUT.VERTICALSPACEMOBILE} -20px`
      : `${LAYOUT.VERTICALSPACEMOBILE} auto`};
  max-width: ${(props: Props): string => (props.fullWidth ? 'auto' : `${LAYOUT.WIDTHSHORT}`)};
  ${MEDIA.medium`
    margin: ${(props: Props): string =>
    props.fullWidth
      ? `${LAYOUT.VERTICALSPACE} -20px`
      : `${LAYOUT.VERTICALSPACE} auto`};
  `}
`

export default SectionRule

export const SectionRuleYellow = styled.hr`
  border: 0;
  background: #e5e5cc;
  height: 2px;
  margin: 0;
`
